import { useEffect, useState, useContext, useRef} from 'react'
import {  Link } from "react-router-dom";
import TextTruncate from 'react-text-truncate';
import {Helmet} from "react-helmet";

import './css/Home.css'
import manSrc from '../images/man.png'
import stSrc from '../images/st3.png'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import { Img, Tasks } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";
import Dictionary from "./popups/Dictionary";

import { MdOutlineSell } from "react-icons/md";
import { TfiStatsUp } from "react-icons/tfi";
import { IoPeopleOutline } from "react-icons/io5";
import { IoIosStats, IoMdPhonePortrait } from "react-icons/io";
import { FiShoppingCart } from "react-icons/fi";
import { GoBriefcase } from "react-icons/go";
import { BsMegaphone, BsEnvelope } from "react-icons/bs";
import { FaQuoteLeft } from "react-icons/fa";



export default function Home() {

	const {success, userinfo, languageText, language} = useContext(AppContext);
	const [open, setopen] = success;
	const [text, settext] = languageText;
	const [lang, setlang] = language;

	const [data, setdata] = useState({});
	const [quiz, setquiz] = useState([]);
	const [cats, setcats] = useState([]);
	const [dc, setdc] = useState([]);
	const [loader, setloader] = useState(true);
	const [openDic, setopenDic] = useState(false);

	useEffect(() => {
		// setloader(true);
		Fetch("Home", (d) => {
			
			if (!d?.error) {
				setdata(d?.articles);
				setquiz(d?.quiz);
				setcats(d?.cats);
				setdc(d?.dc);
			}

			setloader(false);
		});
		
	}, []);

	

	if (loader) {
		return (<Loader />);
	}

	return (
		
		<>
			{openDic && <Dictionary open={openDic} setopen={setopenDic} />}

			<Helmet>

                <title>SpaceUp Learning beyond the cosmos</title>
                

				

            </Helmet>
			<Header data={data?.filter(p => p?.cat?._id == "665073b5cd8c1645d5dbeb5d")[0]} />
			<div className='firstElem'>

				<div className='mainWidth'>
					{data?.filter(p => p?.cat?._id == "665073ebcd8c1645d5dbeb64")?.map((v, k) => {
						if (k < 4) {
							return (
								<Link to={`/Article/${v?._id}`} key={k}>
									<Img src={'img/40x40' + v?.img} /> 
									<div>
										<h2>{lang == "AR" ? v?.ar_title : v?.title}</h2>
										<p>{lang == "AR" ? v?.ar_short : v?.short}</p>
									</div>
								</Link>
							);
						}
						
					})}
					

					
				</div>

			</div>

			<div className='body'>
				<div className='mainWidth'>

					<section className='sect1'>
						
						<div className='sectBox'>
							<div className='options'>

								<div style={{marginBottom:50, width:"100%"}}>
									<hr className='boxLine'/>
									<h3 className='boxTitle'>{lang == "AR" ? cats['66507408cd8c1645d5dbeb6b']?.ar_name : cats['66507408cd8c1645d5dbeb6b']?.name}</h3>
									<p className='boxshort'>{lang == "AR" ? cats['66507408cd8c1645d5dbeb6b']?.ar_full : cats['66507408cd8c1645d5dbeb6b']?.full}</p>
									
								</div>
									
								{data?.filter(p => p?.cat?._id == "66507408cd8c1645d5dbeb6b")?.map((v, k) => {
									if (k < 6) {
										return (
											<Link to={`/Article/${v?._id}`} key={k}>
												<Img src={'img/40x40' + v?.img} />
												<div>
													<h4>{lang == "AR" ? v?.ar_title : v?.title}</h4>
													<text>{lang == "AR" ? v?.ar_short : v?.short}</text>
												</div>
											</Link>
										);
									}
									
								})}
							   
							</div>

							{/* <div className='man'>
								<img src={manSrc} />
							</div> */}
							
						</div>

					</section>

				</div>

				{/* <section className='sect5'>
					<div className='mainWidth'>
						<hr className='boxLine'/>
						<h4 className='boxTitle'>Take a Glance</h4>
						<text className='boxshort'></text>
						
						<div className='sect5Box'>
							{quiz?.length ? quiz?.map((v, k) => {
								return (
									<Tasks data={v} key={k} />
								);
							}) : false }
							
						</div>
						
						
					</div>
				</section> */}

				<section className='sect5'>
					<div className='mainWidth'>
						<hr className='boxLine'/>
						<h4 className='boxTitle'>Take a Glance</h4>
						<text className='boxshort'></text>
						
						<div className='sect5Box'>
							{dc?.map((v, k) => {
								return (
									<div key={k}>
										<Link onClick={() => setopenDic({ _id: v?._id })}>
											<Img src={v?.img} />
											<p>
												<h3>{v?.name}</h3>
												<text>{v?.full}</text>
											</p>
										</Link>
									</div>
								);
							})}
							

							
							
						</div>
						
						
					</div>
				</section> 


				{/* <section className='sect3'>
					<div className='mainWidth'>
						
						<h6 ><span>{lang == "AR" ? cats['6650741ccd8c1645d5dbeb79']?.ar_name : cats['6650741ccd8c1645d5dbeb79']?.name}</span></h6>
						<text>{lang == "AR" ? cats['6650741ccd8c1645d5dbeb79']?.ar_full : cats['6650741ccd8c1645d5dbeb79']?.full}</text>

						<div className='sec3Box'>

							{data?.filter(p => p?.cat?._id == "6650741ccd8c1645d5dbeb79")?.map((v, k) => {
									if (k < 6) {
										return (
											<Link key={k} to={`/Article/${v?._id}`}>
												<FaQuoteLeft />
												<div>
													<b>{lang == "AR" ? v?.ar_title : v?.title}</b>
													<TextTruncate
														line={4}
														truncateText="…"
														text={lang == "AR" ? v?.ar_full : v?.full}
														element="p"
														textElement={"p"}
													/>
													<span>{lang == "AR" ? v?.ar_short : v?.short}</span>
												</div>
												
											</Link>
										);
									}
									
								})}
							

							
						</div>
					</div>
				</section> */}


				{/* <section className='sect6'>
					<div className='mainWidth'>
						
						<hr className='boxLine'/>
						<h6 className='boxTitle'><span>{cats['6650742ecd8c1645d5dbeb80']?.name}</span></h6>
						<text className='boxshort'>{cats['6650742ecd8c1645d5dbeb80']?.full}</text>

						<div className='sect6Box'>

							<div className='left'>
								<img src={stSrc} />
							</div>
							<div className='right'>
								<Link>
									<span>1,322</span>
									<text>Students</text>
								</Link>

								<Link>
									<span>831</span>
									<text>Teachers</text>
								</Link>

								<Link>
									<span>261</span>
									<text>Schools</text>
								</Link>

								<Link>
									<span>8,587</span>
									<text>questions</text>
								</Link>
							</div>

							
							
						</div>
						
					</div>
				</section> */}


			</div>

			<Footer />
		</>
	)
}
