import { useEffect, useState, useContext, useCallback, useMemo, memo} from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";

import {Config} from '../inc/Config'

import './css/Task.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import Randlet, { Img, SpeackBtn } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";
import SendTask from "./popups/SendTask";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdOutlineErrorOutline, MdDeleteOutline, MdEdit, MdCalendarMonth, MdOutlineQuestionAnswer, MdOutlineSchool, MdOutlineAccessTime, MdLightbulbOutline } from "react-icons/md";
import { IoMdRadioButtonOff, IoMdRadioButtonOn, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoCheckmark, IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";





export default function MyTasks() {

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;


	const [loader, setloader] = useState(true);
	const [loader2, setloader2] = useState(false);
	const [data, setdata] = useState([]);

	const [showsendTask, setshowsendTask] = useState(false); 
	const [answers, setanswers] = useState([]); 

	const [step, setstep] = useState(0); 

	const [submitted, setsubmitted] = useState(false); 
	const [error, seterror] = useState(false); 
	const [already, setalready] = useState(false); 

	const [letters, setletters] = useState({}); 

	let loc = useParams();
	let nav = useNavigate();


	function submit() {

		let conf = window.confirm(text?.confirm_task_send);
		if (!conf) {
			return false;
		}

		let sentAnswers = answers;
		sentAnswers = answers?.filter(r => r?.type != 2);


		let elem = document.querySelectorAll(`.questionsBox .answersKet`);
        for (let i = 0; i < elem.length; i++) {
			let elem2 = elem[i].querySelectorAll(`span`);
			let txt = "";
			for (let i2 = 0; i2 < elem2.length; i2++) {

				
				txt += elem2[i2].textContent;
				// elem[i].textContent = "";
			}

			let quest = elem[i].getAttribute("data-question");
			sentAnswers.push({question:parseInt(quest), answer:txt, type:2});

        }

		setloader2(true);
		Fetch("tasks/res", (d) => {

			if (d?.error) {
				setopen({
                    title:text?.alertErrorTitle,
                    short:d?.error,
                    error:true
                });
			} else {

				setopen({
                    title:text?.alertSuccessTitle,
                    short:text?.quiz_sent_success,
                    error:false
                });

				setsubmitted(d?.success);
			}
			
			
			setloader2(false);
		}, { step:2, task:data?.task, answers:sentAnswers });
	}

	function start(v) {
		setloader2(true);
		Fetch("tasks/res", (d) => {

			if (d?.error) {
				setopen({
                    title:text?.alertErrorTitle,
                    short:d?.error,
                    error:true
                });
			} else {
				setstep(1);
			}
			
			setloader2(false);
		}, { step:1, task:v });
	}

	function st_answers(v, k) {
		setanswers(p => [...p?.filter(r => r?.question != v?.ID), {question:v?.ID, answer:k}]);

		if (data?.task?.questions?.length != step) {
			setTimeout(() => {
				setstep(p => p + 1);	
			}, 100);
		}

	}

	function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url?.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }


	useEffect(() => {

		Fetch("tasks/start", (d) => {

			if (d?.error) {
				seterror(d?.error);
			} else {
				setdata(d);
				if (d?.already?.finished) {
					setalready(d?.already);
				}

				if (d?.already?.answers?.length) {
					setanswers(d?.already?.answers);



					setTimeout(() => {

						let elem2 = document.querySelectorAll(`.ketData b`);
						for (let i = 0; i < elem2.length; i++) {
							elem2[i].classList.add("disabled");
						}
						
						d?.already?.answers?.map((v) => {

						
							let elem = document.querySelectorAll(`#id-${v?.question} .answersKet span`);
							for (let i = 0; i < elem.length; i++) {
								
								if (v?.answer) {
									// console.log("aa");
									elem[i].textContent = v?.answer?.split("")[i];
								}
								
							}
						
						});
					}, 1000);
					

				}
				
			}

			setloader(false);
		}, { _id: loc?.id });


	}, []);
	

	
	if (loader) {
		return (
			<Loader />
		);
	}

	if (error) {
		return (
			<div className='answerSuccess er'>
				<div>
					<MdOutlineErrorOutline />
					<h1>{text?.alertErrorTitle}</h1>
					<p>{error}</p>

					<a href='javascript:void(0)' onClick={() => nav(-1)} className='btn'>{text?.return_to_my_tasks}</a>
				</div>
				

			</div>
		);
	}

	

	if (submitted) {
		return (
			<div className='answerSuccess'>
				<div>
					<IoIosCheckmarkCircleOutline />
					<h1>{text?.alertSuccessTitle}!!</h1>
					<p>{text?.quiz_sent_success}.</p>

					{submitted?.score ?
						<>
						{submitted?.score <= 50 ?
							<score className="error">{submitted?.score}%</score>
						:
							<score >{submitted?.score}%</score>
						}
						<b>{text?.your_score_is}:</b>
						
						</>
					: false}

					<a href='javascript:void(0)' onClick={() => nav(-1)} className='btn'>{text?.return_to_my_tasks}</a>
				</div>
				

			</div>
		);
	}



	let splitCls = logedIn?.userinfo?.classes[0]?.split("-")
	let className = `${global.Classes[splitCls[0]]} (${global.Grades[splitCls[1]]})`

	
	
	return (
		<>
			<Header small={true}/>

			<div className='body taskBox' >

				{loader2 && <Loader />}
				<div className='mainWidth '>

					<div className='cp'>
						<div className='containerBox' >

							<div className={`sectionInfo ${step && "quizHide"}`}>
								<h1><r>{text?.task_info}</r></h1>
								
								<div className='teeacherInfo'>
									<Img src={`img/60x60${data?.task?.user?.img}`} />
									<div>
										<text>{data?.task?.user?.name}</text>
										<text>{data?.task?.user?.city}</text>
									</div>
								</div>

								{already?.finished && 
										<div className='answerStats'>
											<li>
												<span style={{color:"#57cb58"}}>{already?.score}%</span>
												<b>{text?.score}</b>
											</li>

											<li>
												<span>{already?.timer}</span>
												<b>{text?.task_time}</b>
											</li>
											
						
										</div>
									}

								<ul>

									
									<li>
										<MdOutlineQuestionAnswer />
										<span>Number of questions: <b>{data?.task?.questions?.length}</b></span>
									</li>

									<li>
										<MdOutlineSchool />
										<span>{className}</span>
									</li>

									<li>
										<MdCalendarMonth />
										<span>{text?.starts}: <b>{dateToText(data?.task?.date_start)}</b></span>
									</li>

									<li>
										<MdCalendarMonth />
										{data?.task?.date_end ? 
											<span>{text?.ends}: <b>{dateToText(data?.task?.date_end)}</b></span>
										:
											<span>{text?.ends}: <b>undefined</b></span>
										}
									</li>

									<li>
										<MdOutlineAccessTime />
										{data?.task?.timer ? 
											<span>{text?.timer}: <b>{data?.task?.timer}</b></span>
										:
											<span>{text?.timer}: <b>undefined</b></span>
										}
									</li>

									<li>
										<MdLightbulbOutline />
										<span>Grading: <b>{data?.task?.res_type == 1 ? "Automatic" : "Teacher decision"}</b></span>
									</li>

									

									<li style={{width:"98%"}}>
										<MdLightbulbOutline />
										<span>{text?.comments}: <b>{data?.task?.full ? data?.task?.full : "--"}</b></span>
									</li>


									

								</ul>
								
								<div className='btnSection'>

									<a href='javascript:void(0)' onClick={() => already ? setstep(1) : start(data?.task)} className='btn'>{text?.lets_start}</a>
									<a href='javascript:void(0)' onClick={() => nav(-1)} className='btn gray'>{text?.cancel}</a>
									
								</div>
								
							</div>

							{data?.task?.questions?.map((v, k) => {

									console.log(v?.answersType, v?.answers);

								let trueAnswer = false;
								if (already?.finished) {
									v?.answers?.map((v2, k2) => { 
										
										if (!v?.answersType) {
											if (v2?.true) {
												let trueA2 = answers?.filter(p => p?.question == v?.ID && p?.answer == k2)?.length;
												if (trueA2) {
													trueAnswer = true;
												}
											}
										} else if (v?.answersType == 2) {

											let trueA2 = answers?.filter(p => p?.question == v?.ID && p?.answer?.toUpperCase() == v2?.answer.toUpperCase());
											
											if (trueA2?.length) {
												trueAnswer = true;
											}


										}
										

									})
								}


								let yID = youtube_parser(v?.video?.video_url);
								
								
 
								return (
									<div className={`questionsBox ${step == k + 1 && "show"}`} id={`id-${v?.ID}`}>
										{already?.finished && <>
											{trueAnswer ? <FaCheck className='answerTrueFalse answerTrue' /> : <IoClose className='answerTrueFalse answerFalse' /> }
										</>}
										
										<h1>{text?.question} ({k + 1}) </h1>

										<div className='questionsBoxQ'>
											{v?.type == 1 && <h3>{v?.question}</h3>}
											{v?.type == 2 && 
												<div className='qVideoPlayer'>
													<iframe  src={`https://www.youtube.com/embed/${yID}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
													<h3>{v?.video?.video_title}</h3>
													<p>{v?.video?.video_desc}</p>
												</div>
											}
											{v?.type == 3 && 
												<div className='qVideoPlayer'>
													<audio controls style={{width:"100%"}}>
														<source src={`${Config?.imgUrl}${v?.file?.file}`} type="audio/mpeg" />
													</audio>
													<h3>{v?.file?.file_title}</h3>
													<p>{v?.file?.file_desc}</p>
												</div>
											}
											{v?.type == 4 && 
												<div className='qVideoPlayer'>
													<img style={{width:"100%", aspectRatio:16/9, objectFit:"cover", borderRadius:10, border:"1px solid #eee"}} src={`${Config?.imgUrl}${v?.file?.file}`} />
													<h3>{v?.file?.file_title}</h3>
													<p>{v?.file?.file_desc}</p>
												</div>
											}
											{v?.type == 5 && 
												<div className='qVideoPlayer'>
													<SpeackBtn text={v?.file?.file_word} />
													<h3>{v?.file?.file_title}</h3>
													<p>{v?.file?.file_desc}</p>
												</div>
											}

											{!v?.answersType && 
											<div className='answersBox'>
												{v?.answers?.map((v2, k2) => { 
											

													let checked = answers?.filter(p => p?.question == v?.ID && p?.answer == k2)?.length;
	
													if (already?.finished) {
														return (
															<a href='javascript:void(0)' onClick={() => !already && st_answers(v, k2)} className={checked ? "checked" : "none"}>
																{v2?.true ? <IoMdRadioButtonOn /> : <IoMdRadioButtonOff />}
																<span>{v2?.answer}</span>
															</a>
														);
													} else {
														return (
															<a href='javascript:void(0)' onClick={() => !already && st_answers(v, k2)} className={checked ? "checked" : "none"}>
																{checked ? <IoMdRadioButtonOn /> : <IoMdRadioButtonOff />}
																<span>{v2?.answer}</span>
															</a>
														);
													}
													
												
												})}
											</div>
											}

											{v?.answersType == 2 && 
										
												<Randlet data={v?.answers[0]?.answer} id={v?.ID} finished={already?.finished}  />
									
											}

											<div className='btnSection' style={{justifyContent:"flex-end"}}>
									
												<a href='javascript:void(0)' onClick={() => setstep(p => p - 1)} className='btn gray'>{text?.back}</a>
												
												{data?.task?.questions?.length == step ?
													<>

													{!already?.finished && <a onClick={() => submit()} className='btn'>{text?.send_quiz_now}</a>}
													</>
												:
													<a href='javascript:void(0)' onClick={() => setstep(p => p + 1)} className='btn'>{text?.next} {text?.question}</a>
												}
											</div>

										</div>
									</div>
								);
							})}
							
							

						</div>
					</div>

				</div>
			</div>

		</>
	);
		
	
}
