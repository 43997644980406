import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import SendTask from "./popups/SendTask";
import { Img } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";

export default function Cp() {

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [loader, setloader] = useState(true);
	const [data, setdata] = useState([]);
	
	const [showsendTask, setshowsendTask] = useState(false); 


	function deleteTask(v) { 

		let conf = window.confirm(text?.deleteConfirm);
		if (!conf) {
			return false;
		}

		Fetch("tasks/delete", (d) => {
			
			setdata(p => {
				let arr = [];
				p?.tasks?.map((r) =>{
					if (r?._id != v) {
						arr.push(r);
					}
					
				});
				
				
				return { stats:p?.stats, tasks:arr}
			});

			setloader(false);
		}, { _id: v });

	}

	useEffect(() => {

			
		Fetch("tasks/view", (d) => {

			if (!d?.error) {
				setdata(d);
			} else {
				setopen({
                    title:text?.alertErrorTitle,
                    short:d?.error,
                    error:true
                });
			}

			setloader(false);
		});

	}, []);

	// console.log(data);

	return (
		
		<>
			{showsendTask && <SendTask setopen={setshowsendTask} open={showsendTask} />}
			<Header title={text?.cp_title} />
			<div className='firstElem stats'>

				<div className='mainWidth'>

					<section>
						<div>
							<b>{text?.stats_task}</b>
							<h3>{data?.stats?.sent}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>
					<section>
						<div>
							<b>{text?.stats_myStudents}</b>
							<h3>{data?.stats?.students}</h3>
							{/* <span className='up'><FaArrowUp /> 10.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.stats_grade_avg}</b>
							<h3>{data?.stats?.avg}%</h3>
							{/* <span><FaArrowDown /> 2.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.stats_tasks_sent}</b>
							<h3>{data?.stats?.sent}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>


				</div>
			</div>


			<div className='body'>
				<div className='mainWidth '>

					<div className='cp'>

						<div className='containerBox' style={{width:300}}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle'>{text?.profile}</h3>

							<ul className='profile'>
								<div className='pImg'>
									<Img src={'img/100x100' + logedIn?.userinfo?.img} />
									<h5>{logedIn?.userinfo?.name}</h5>
								</div>
								<li><span>{text?.username}:</span><b>{logedIn?.userinfo?.user}</b></li>
								<li><span>{text?.level}:</span><b>{logedIn?.userinfo?.userlevel == 1 && "Student"}{logedIn?.userinfo?.userlevel == 3 && "Teacher"}{logedIn?.userinfo?.userlevel == 5 && "Manager"}</b></li>
								<li><span>{text?.fullname}:</span><b>{logedIn?.userinfo?.name}</b></li>
								<li><span>{text?.account_status}:</span><b>{logedIn?.userinfo?.status >= 1 ? "Active" : "Deactive"}</b></li>
								<li><span>{text?.city}:</span><b>{logedIn?.userinfo?.city}</b></li>
								<li><span>{text?.school}:</span><b>{logedIn?.userinfo?.school_id[0]?.name}</b></li>
								<li><span>{text?.classes}:</span><b>{logedIn?.userinfo?.classes?.map((c, kk) => {
										let classNames = `${global.Classes[c[0]]} (${global.Grades[c[2]]})`
										return (
											<span key={kk} className='tag' style={{margin:"4px auto", color:"#fff"}}>{classNames}</span>
										);
									})}	</b></li>
							</ul>
						</div>


						<div className='containerBox' style={{flex:1, }}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle'>{text?.tasks}</h3>

							<div className='quizzesList'>
								<table>
								<thead>
										<tr>
											<td style={{width:"40%"}} className='align-left'>{text?.task_name}</td>
											<td>{text?.score}</td>
											<td>{text?.classes}</td>
											<td>{text?.waiting}</td>
											<td style={{width:"20%"}}>{text?.actions}</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.tasks?.map((v, k) => {
											return (
												<tr key={k}>
													<td className='align-left'><b>{v?.name}</b> {v?.full && <><br /><p>{v?.full}</p></>}</td>
													<td>{v?.avg ? <span className='tag green'>{v?.avg}%</span> : "--"}</td>
													<td>
														{v?.classes?.map((c, kk) => {

															let clsSplit = c?.split("-");
															let classNames = `${global.Classes[clsSplit[0]]} (${global.Grades[clsSplit[1]]})`
															return (
																<span key={kk} className='tag' style={{margin:"4px auto"}}>{classNames}</span>
															);
														})}
													</td>
													<td>{v?.not}</td>
													<td>
														<Link to={`/MyTasks/${v?._id}`} className='tableOptions blue'><MdOutlineGroup /></Link>
														&nbsp;&nbsp;
														<a onClick={() => deleteTask(v?._id)} href='javascript:void(0)' className='tableOptions red'><MdDeleteOutline /></a>
														&nbsp;&nbsp;
														<a onClick={() => setshowsendTask(v)} href='javascript:void(0)' className='tableOptions green'><MdEdit /></a>
														
														
													</td>
												</tr>
											);
										})}
										
									</tbody>
								</table>
							</div>
							
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
}
