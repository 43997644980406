import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";

import './css/Header.css'
import { AppContext } from "../Context";
import logo from "../images/logo.png";
import Login from "./popups/Login";
import Dictionary from "./popups/Dictionary";
import Fetch, { Loader } from '../inc/Fetch'
import { Config } from '../inc/Config'
import { MainMenu, MainUserMenu } from "../components/Menu";
import { Img, LanguageSelect } from "../components/Elements.js";


import { IoIosArrowDown, IoMdArrowDropright } from "react-icons/io";
import { IoMenu } from "react-icons/io5";


export default function Header({small, title, data, size}) {

    if (!title) {
        title = "";
    }
    

    
    const loc = useLocation();
    const navigate = useNavigate();

    const {success, userinfo, language, languageText, loginForm} = useContext(AppContext);
    const [open, setopen] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [lang, setlang] = language;
    const [text, settext] = languageText;
    const [openLogin, setopenLogin] = loginForm;

    const [mobileMenu, setmobileMenu] = useState(false);
    const [openDic, setopenDic] = useState(false);
    const [access, setaccess] = useState(false);

    // function accessb() {
        
    // }

    useEffect(() => {
        
        if (logedIn?.userinfo?._id) {
            
            Fetch("tasks/tasks_stats", (d) => {
                
                if (d?.error == "login"){
                    setlogedIn({});
                }
                setlogedIn(p => ({
                    ...p,
                    tasks_num:d?.num,
                }))
            });
        }

        setmobileMenu(false);
    }, [loc?.pathname])
    
    if (lang == "AR" && data) {
        data.title = data?.ar_title;
        data.short = data?.ar_short;
    }

    return (
        <>
            {openLogin && <Login setopen={setopenLogin} open={openLogin} />}
            {openDic && <Dictionary setopen={setopenDic} />}
            
            <div className={`mobileScreen slideMenu ${mobileMenu ? 'open' : 'closed'}`}>
                <a onClick={() => setmobileMenu(false)} className='black' />
                <div className={`menuBox`}>

                    {logedIn?.userinfo?._id && <Link className='userImgIn'>
                        
                        <Img src={"img/60x60" + logedIn?.userinfo?.img} />
                        <div >
                            <h3>{logedIn?.userinfo?.name}</h3>
                            <h4>
                                {logedIn?.userinfo?.userlevel == 1 && text?.you_are_loged_student}
                                {logedIn?.userinfo?.userlevel == 3 && text?.you_are_loged_teacher}
                            </h4>
                        </div>
                        <IoMdArrowDropright />
                    </Link>}


                    <div className='allMenusMobile'>

                        <nav className='regularMenu'>
                            <MainMenu />
                        </nav>

                        {logedIn?.userinfo?._id && <nav className='userMenu'>
                            <MainUserMenu setopenDic={setopenDic}/>
                        </nav>}
                    </div>
                    

                </div>
            </div>

            <div className='backGMain'>
                <header>
                    <nav>
                        <div className='mainWidth'>
                            
                            
                            <div style={{display:"flex", gap:30, alignItems:"flex-end"}}>
                                <Link to="/" className='logo'>
                                    <img src={logo} />
                                </Link>
                                <div className='left'>
                                    <MainMenu />
                                </div>
                            </div>

                            {logedIn && 
                            <>
                            {logedIn?.userinfo?._id ? 
                                <div className='right'>
                                
                                    <ul role="tab" tabindex="0" onKeyDown={(e) => {
                                        if(e.key === 'Enter'){
                                            setaccess(p => !p)
                                        }
                                    }} onClick={(e) => {
    
                                        setaccess(p => !p)
                             
                                    }} className={`userBox ${access ? "openMenu" : ""}`}>
                                        <div>
                                            {logedIn?.tasks_num ? <b>{logedIn?.tasks_num}</b> : false}
                                            <IoIosArrowDown />
                                            <Img src={"img/60x60" + logedIn?.userinfo?.img} />
                                        </div>

                                        {access && <MainUserMenu setopenDic={setopenDic}/>}
                                        
                                    </ul>

                                    {/* <LanguageSelect /> */}
                            
                                </div>
                            : 
                                <div className='right'>
                                    
                                    <Link onClick={() => setopenLogin(p => !p)} className='btn'>
                                        {text?.lets_start}
                                    </Link>

                                    {/* <LanguageSelect /> */}

                                </div>
                            }

                            </>
                            }
                            
                            <div className='mobileScreen menuCont' >

                                {logedIn?.userinfo?._id ? <div onClick={() => navigate('/Cp')}>
                                    {logedIn?.tasks_num ? <b>{logedIn?.tasks_num}</b> : false}
                                    <IoIosArrowDown />
                                    <Img src={logedIn?.userinfo?.img} />
                                </div>:

                                <Link onClick={() => setopenLogin(p => !p)} className='btn'>
                                    {text?.lets_start}
                                </Link>
                                }
                                <a onClick={() => setmobileMenu(p => !p)} className='menuBtn'><IoMenu /></a>
                                

                            </div>
                            
                            
                        </div>
                        
                    </nav>
                    
                </header>
                {!small && 
                    <>
                    {loc?.pathname == "/" ? 
                        <div className='effect'>
                            
                            <div className='mainWidth'>
                                <div className='textEffect'>
                                    <h1>{data?.title} </h1>
                                    <p>{data?.short}</p>
                                    <Link to={`/Article/${data?._id}`} className='btn'>{text?.moreInfo}</Link>
                                </div>
                            </div>
                        </div>
                    :
                        <div className='effect small'>
                                    
                            <div style={size ? { maxWidth: size} : {}} className='mainWidth'>
                                {Array.isArray(title) ? 
                                    <div className='headPath'>
                                        {title?.map((v, k) => {
                                            if (k + 1 == title?.length) {
                                                return (
                                                    <Link to={v?.link} className='main'>{v?.name}</Link>
                                                );
                                            } else {
                                                return (
                                                    <Link to={v?.link}>{v?.name} / </Link>
                                                );
                                            }
                                            
                                        })}
                                    </div>
                                : 
                                    <h1>{title}</h1>
                                }
                                
                            </div>
                        </div>
                    }
                    </>
                }
            </div>
        </>
    )
}
