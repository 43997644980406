import { memo, useEffect, useState, useContext, useRef } from 'react';

import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";

import { MdOutlineFavoriteBorder, MdFavorite } from "react-icons/md";

export function SaveFav({v}) { 
    
    const {success, userinfo} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;

    const [ck, setck] = useState(false);

    function save() { 

        if (!logedIn) {
            setsuc({
                title:"Ops!!",
                short:"You need to be logged in to use favorites.",
                error:true
            });
            return false;
        }

        Fetch("questions/fav/add", (d) => {
            
            // setck(d?.success);
            if (d?.success) {

                setlogedIn(p => ({
                    ...p,
                    fav:[...p?.fav, v],
                }))

                setsuc({
                    title:"Yay..",
                    short:"Question added to favorites.",
                    error:false
                });
            } else {

                setlogedIn(p => ({
                    ...p,
                    fav:p?.fav?.filter(r => r?.ID != v?.ID),
                }))
            }
        }, v);
    }

    
    if (!logedIn) {
        return false;
    }

    return (
        <a onClick={save} className={`btn fav ${logedIn?.fav?.filter(r => r?.ID == v?.ID)?.length && "on"}`}>{logedIn?.fav?.filter(r => r?.ID == v?.ID)?.length ? <MdFavorite /> : <MdOutlineFavoriteBorder className='on'/>}</a>
    )

}