import { useEffect, useState, useRef } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie'

import './App.css';
import "./inc/Classes";
import { IoMdArrowDropdown, IoIosNotifications } from "react-icons/io";

import { AppContext } from "./Context";

import Home from "./pages/Home";
import QuizView from "./pages/QuizView";

// users 
import Cp from "./pages/Cp";
import StudentCp from "./pages/StudentCp";
import Tasks from "./pages/Tasks";
import Games from "./pages/Games";
import Game from "./pages/Game";
import Training from "./pages/Training";
import Test from "./pages/Test";
import Questions from "./pages/Questions";
import MyTasks from "./pages/MyTasks";
import MyTasksInfo from "./pages/MyTasksInfo";
import MyTasksStudentInfo from "./pages/MyTasksStudentInfo";
import StudentTasks from "./pages/StudentTasks";
import TaskStart from "./pages/TaskStart";
import Profile from "./pages/Profile";
import Article from "./pages/Article";
import Students from "./pages/Students";
import Fav from "./pages/Fav";
import ErrorPage from "./pages/ErrorPage";

import QuizPrev from "./pages/popups/QuizPrev";
import SendTask from "./pages/popups/SendTask";
import Question from "./pages/popups/Question";

import Success from "./components/Success";


import Fetch, { Loader, dateToText } from './inc/Fetch'

function App() {
    
    const [cookies, setCookie] = useCookies(['lang']);

    const initialized = useRef(false);
    
    const location = useLocation();
    const previousLocation = location.state?.previousLocation;

    const [loading, setloading] = useState(true);
    const [logedIn, setlogedIn] = useState(false);
    const [success, setsuccess] = useState(false);
    const [showQuiz, setshowQuiz] = useState(false); 
	const [showsendTask, setshowsendTask] = useState(false); 
	const [showQ, setshowQ] = useState(false); 
	const [text, settext] = useState({}); 
	// const [lang, setlang] = useState(!cookies?.lang ? "EN" : cookies?.lang); 
	const [lang, setlang] = useState("EN"); 
	const [foot, setfoot] = useState({}); 
	const [openLogin, setopenLogin] = useState(false); 

    const contextVars = {
        userinfo:[logedIn, setlogedIn], 
        success:[success, setsuccess], 
        showQuiz:[showQuiz, setshowQuiz], 
        showsendTask:[showsendTask, setshowsendTask], 
        showQuestion:[showQ, setshowQ], 
        language:[lang, setlang], 
        languageText:[text, settext], 
        footerText:[foot, setfoot], 
        loginForm:[openLogin, setopenLogin], 
    }

    function checkLogin() { 
        
        Fetch("checkLogin", (d) => { 

            if (!d?.success) {
                setlogedIn({});
            } else {
                setlogedIn({userinfo: d?.success?.userinfo, fav:d?.success?.fav});

            }
            setloading(false);
            
        });
        
    }

    async function languageChange() {

        const html = document.querySelector("html");
        html.setAttribute("lang", lang);

        await require(`./lang/AR.css`); 

        const module = await require(`./lang/${lang}`); 
        settext(module);
    }


    useEffect(() => {
        languageChange();
    }, [lang]);

    useEffect(() => {
       
        

        
        if (!initialized.current) {
            initialized.current = true;
            checkLogin(); 
        }

    }, []);

    return (
        <AppContext.Provider value={contextVars}>
            {success && <Success data={success}/>}

            {showQuiz && <QuizPrev setopen={setshowQuiz} openTasks={setshowsendTask} open={showQuiz} />}
            {showsendTask && <SendTask setopen={setshowsendTask} open={showsendTask} />}
            {showQ && <Question setopen={setshowQ} open={showQ} />}

            <Routes>

                

                <Route path="/" element={<Home />} />
                <Route path="/Article/:id" element={<Article />} />
                
                <Route path="/Games" element={<Games />} />
                <Route path="/Game/:id" element={<Game />} />

                <Route path='*' exact={true} element={<ErrorPage />} />
                {/* <Route path="/Test" element={<Test />} /> */}
                {logedIn?.userinfo?._id && 
                    <>
                        <>
                           

                            {logedIn?.userinfo?.userlevel >= 3 &&
                            <> 
                                <Route path="/Cp" element={<Cp />} />
                                <Route path="/Tasks" element={<Tasks />} />
                                <Route path="/Questions" element={<Questions />} />
                                <Route path="/Questions/:id" element={<Questions />} />
                                <Route path="/Quiz/view/:id" element={<QuizView />} />
                                <Route path="/MyTasks" element={<MyTasks />} />
                                <Route path="/MyTasks/:id" element={<MyTasksInfo />} />
                                <Route path="/MyTask/:id/:class" element={<MyTasksInfo />} />
                                <Route path="/MyTasks/:id/:student" element={<MyTasksStudentInfo />} />
                                <Route path="/Students" element={<Students />} />
                                <Route path="/Fav" element={<Fav />} />
                                <Route path="/Profile" element={<Profile />} />
                            </>
                            }
                            {logedIn?.userinfo?.userlevel < 3 &&
                            <>
                                <Route path="/Cp" element={<StudentCp />} />
                                
                                <Route path="/Tasks" element={<Tasks />} />
                                <Route path="/Quiz/view/:id" element={<QuizView />} />
                                <Route path="/MyTasks" element={<StudentTasks />} />
                                <Route path="/Profile" element={<Profile />} />
                            </>
                            }
                            
                            
                            <Route path="/TaskStart/:id" element={<TaskStart />} />
                            
                            <Route path="/Training" element={<Training />} />
                            
                            
                        </>
                        
                    </>
                }
                
                
                

            </Routes>

            
                
        </AppContext.Provider>
    );
}

export default App;
