import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";


import './css/Tasks.css'
import { AppContext } from "../Context";
import Fetch, { Loader } from '../inc/Fetch'
import { Tasks } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";

const levels = [
    {
        name:"- All levels -",
        val:false,
    },
    {
        name:"Beginners",
        val:"Beginners",
    },
    {
        name:"Intermediate",
        val:"Intermediate",
    },
    {
        name:"Advanced",
        val:"Advanced",
    }
];


import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { GiShare } from "react-icons/gi";
import { IoIosAdd } from "react-icons/io";


export default function Cp() {

	const active = useRef(true);
	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	

	

	useEffect(() => {

		
		if (active.current != filter) {
			active.current = filter;

			setpageLoad(true);

			Fetch("Quiz/view", (d) => {

				if (!d) {
					setopen({
						title:text?.alertErrorTitle,
						short:text?.cant_get_data,
						error:true
					});
				} else {

					setdata(d);

					let arrCats = [{ name: `- ${text?.all_categories} -`, val:false}];
					d?.cats?.map((v) => {
						arrCats.push({ name: v?.name, val:v?._id});
					});
					setcats(arrCats);

					let arrClas = [{ name: `- ${text?.all_classes} -`, val:false}];
					Object.keys(global.Classes)?.map((v) => {
						arrClas.push({ name: global.Classes[v], val:v});
					});
					setcls(arrClas);
				}

				setpageLoad(false);
			}, { filter:filter});
		
		}
	}, [filter]);

	

	return (
		
		<>
			
			<Header title="New Task" />
			<div className='firstElem search'>
				<div className='mainWidth'>

					<div className='filterBoxTop'>

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={levels}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                level:v,
                            }))}
                            value={filter?.level}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cats}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                cats:v,
                            }))}
                            value={filter?.cats}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cls}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                classes:v,
                            }))}
                            value={filter?.classes}
                        />
			


					</div>

				</div>
			</div>


			<section className='sect5 tasks'>

				{pageLoad ? <Loader /> :

				<div className='mainWidth'>
					<hr className='boxLine'/>
					<h4 className='boxTitle'>Tasks List</h4>
					
					<div className='sect5Box'>
						{data?.tasks?.length ? data?.tasks?.map((v, k) => {
							return (
								<Tasks key={k} data={v} />
							);
						})
						
						:
							<div></div>
						}

					</div>
					
					
				</div>}
			</section>

			<Footer />
		</>
	)
}
