import { memo, useEffect, useState, useContext, useRef } from 'react';

import './css/Dictionary.css'
import manSrc from '../../images/moe.png'

import { AppContext } from "../../Context";
import Popups from "../../components/Popups";
import { Input } from "../../components/Form";

import Fetch, { Loader, dateToText, PlaySound } from "../../inc/Fetch";

import { IoIosPlayCircle } from "react-icons/io";


export default memo(function Login({setopen, open}) {

    const [miniTabs, setminiTabs] = useState(0);

    const active = useRef(true);
    const {success, userinfo, languageText} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [text, settext] = languageText;

    
    const [data, setdata] = useState({});
    const [values, setvalues] = useState(open?._id ? { cat:open?._id } : {});
    const [loader, setloader] = useState(false);
    const [mainloader, setmainloader] = useState(true);

    console.log(values);
    
    useEffect(() => {
        
        if (!logedIn?.userinfo?._id) {
            setsuc({
                title:text?.alertErrorTitle,
                short:`${text?.not_logged_in}.`,
                error:true
            });

            
            setopen(false);
        } else {
            // setloader(true);
            Fetch("dictionary/view", (d) => {
                if (d?.error) {

                    setsuc({
                        title:text?.alertErrorTitle,
                        short:d?.error,
                        error:true
                    });

                    setopen(false); 
                } else {
                    setdata(d);
                }

                setmainloader(false);
                setloader(false);
            }, values);

        }
    
      
    }, [values]);
    
    
    if (mainloader) {
        return false;
    }
    return (

        <Popups setOpen={setopen} style={{maxWidth:550, borderTop:"4px solid #fd745a"}}>
           
           <>
            {loader && <Loader />}

            <div className='popupBody interface data quizSend' style={{background:"#f8f8f8"}}>

                <h2 style={{padding:"15px 0 0 20px", fontWeight:600, color:"#555", fontSize:18}}>{text?.dictionary}</h2>

                {miniTabs == 0 && 
                    <div className={`tabsSection active`}>

                        <div className='searchDicBox'>
                            <input autofocus="true" type='search' name='q' onChange={(e) => setvalues(p => ({
                                    ...p,
                                    search:e?.target?.value,
                                }))} placeholder={`${text?.dic_search_holder}...`} />
                            <select onChange={(e) => setvalues(p => ({
                                    ...p,
                                    cat:e?.target?.value,
                                }))}>
                                <option value=''>{text?.all_categories}...</option>
                                {data?.cats?.map((v, k) => {
                                    return (
                                        <option key={k} value={v?._id}>{v?.name}</option>
                                    );
                                })}
                            </select>
                        </div>


                        <div className='dicResBox'>
                            <table>
                                {data?.words?.map((v, k) => {
                                    return (
                                        <tr>
                                            <td>{v?.eng_name}</td>
                                            <td>{v?.ar_name}</td>
                                            <td><a href='javascript:void(0)' onClick={() => PlaySound(v?.eng_name)}><IoIosPlayCircle  /></a></td>
                                        </tr>
                                    );
                                })}
                                
                            </table>
                            
                        </div>

                    </div>
                }



                </div>
            </> 

            

        </Popups>
        
    );
})